<template>
  <div>
    <Menu />

    <section>
      <div class="container-fluid g-0 pt-3">
        <div class="row g-0">
          <div class="text-center">
            <div class="winelands-banner">
              <div class="row">
                <div class="col-lg-6 mx-auto align-self-center switch-white pb-5">
                  <h1 class="display-2 pb-5">The Winelands</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-10 text-center pt-5">
            <h2 class="switch-blue">Welcome to The Winelands</h2>
            <p class="switch-blue">Orchards, vineyards and mountains – you’re spoiled for choice when it comes to picture-perfect views in the Winelands. This region is also celebrated for its world-renowned wines, award-winning restaurants and quaint towns with rich histories. There’s much to offer both residents and visitors, from outdoor activities and wine tasting routes, to popular festivals, food markets, high-quality retail shopping and boutique stores. </p>
          </div>
        </div>
      </div>
    </section>

    <section class="discover mt-5">
      <div class="container-fluid h-100">
        <div class="row h-100 justify-content-center">
          <div class="col-md-5 h-100">
            <div class="switch-bg-lgrey h-100 p-5 bg-opacity text-center"> 
              <h2 class="switch-blue">Discover</h2>
              <p class="switch-blue"><strong>Close to Cape Town:</strong> While you can appreciate the safe and scenic beauty of living in the Winelands, an additional appeal of the area is that you have easy access to Cape Town’s CBD and the Cape Town International Airport.</p>
              <p class="switch-blue"><strong>Schools and universities:</strong> Access to quality education is an important factor when a family decides where to live. The Winelands and surrounding areas boast many of the country’s top-ranked schools as well as some excellent universities. </p>
              <h2 class="switch-blue mt-5">Lifestyle</h2>
              <p class="switch-blue">Many families and retirees are leaving the hustle and bustle of city living, in favour of the tranquil pace of life in the Winelands. With wide-open spaces, secure estates and plenty of activities, it’s a desirable destination for homeowners.</p>
              <p class="switch-blue"></p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div>
              <h2 class="switch-blue mt-5 text-center">Activities</h2>
              <div class="row mb-5 pb-3">

                <div class="col-md-6 mb-4">
                  <div 
                    class="switch-bg-lblue p-5 h-100 video-container" 
                    @mouseover="playVideo($event.currentTarget)" 
                    @mouseout="pauseVideo($event.currentTarget)"
                  >
                    <video muted loop class="background-video" poster="/images/poster-golf.png">
                      <source src="/images/videos/golf.mp4" type="video/mp4">
                      Your browser does not support the video tag.
                    </video>
                    
                    <!-- Title at the top-left -->
                    <h4 class="switch-white title-top-left"></h4>
                    
                    <!-- Text section anchored to the bottom -->
                    <div class="container cover-panel">
                      <div class="row">
                        <div class="col-md-9">
                          <p class="switch-white">
                            <strong>Golf</strong> - Choices are endless when it comes to teeing off on a prestigious golf course in the Winelands...
                          </p>
                        </div>
                        <div class="col-md-3">
                          <button class="fullscreen-btn btn-small mt-3" @click="viewFullScreen($event.currentTarget)"><small>Watch Video</small></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 mb-4">
                  <div 
                    class="switch-bg-lblue p-5 h-100 video-container" 
                    @mouseover="playVideo($event.currentTarget)" 
                    @mouseout="pauseVideo($event.currentTarget)"
                  >
                    <video muted loop class="background-video" poster="/images/poster-equestrian.png">
                      <source src="/images/videos/equestrian.mp4" type="video/mp4" poster="">
                      Your browser does not support the video tag.
                    </video>
                    <h4 class="switch-white title-top-left"></h4>
                    <div class="container cover-panel">
                      <div class="row">
                        <div class="col-md-9">
                          <p class="switch-white"><strong>Equestrian</strong> - Ride through vineyards and orchards, along rivers and lakes, and explore country roads and forest paths...</p>
                        </div>
                        <div class="col-md-3">
                          <button class="fullscreen-btn btn-small mt-3" @click="viewFullScreen($event.currentTarget)"><small>Watch Video</small></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 mb-4">
                  <div 
                    class="switch-bg-lblue p-5 h-100 video-container" 
                    @mouseover="playVideo($event.currentTarget)" 
                    @mouseout="pauseVideo($event.currentTarget)"
                  >
                    <video muted loop class="background-video" poster="/images/poster-padel.png">
                      <source src="/images/videos/padel.mp4" type="video/mp4">
                      Your browser does not support the video tag.
                    </video>
                    <h4 class="switch-white title-top-left"></h4>
                    <div class="container cover-panel">
                      <div class="row">
                        <div class="col-md-9">
                          <p class="switch-white"><strong>Padel</strong> - It’s active, fun and social – three reasons why it’s the fastest growing sport in Africa...</p>
                        </div>
                        <div class="col-md-3">
                          <button class="fullscreen-btn btn-small mt-3" @click="viewFullScreen($event.currentTarget)"><small>Watch Video</small></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 mb-4">
                  <div 
                    class="switch-bg-lblue p-5 h-100 video-container" 
                    @mouseover="playVideo($event.currentTarget)" 
                    @mouseout="pauseVideo($event.currentTarget)"
                  >
                    <video muted loop class="background-video" poster="/images/poster-watersports.png">
                      <source src="/images/videos/watersports.mp4" type="video/mp4">
                      Your browser does not support the video tag.
                    </video>
                    <h4 class="switch-white title-top-left"></h4>
                    <div class="container cover-panel">
                      <div class="row">
                        <div class="col-md-9">
                          <p class="switch-white"><strong>Water Sports</strong> - With the Berg River running through our biggest estate, water sports are a popular pastime...</p>
                        </div>
                        <div class="col-md-3">
                          <button class="fullscreen-btn btn-small mt-3" @click="viewFullScreen($event.currentTarget)"><small>Watch Video</small></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 mb-4">
                  <div 
                    class="switch-bg-lblue p-5 h-100 video-container" 
                    @mouseover="playVideo($event.currentTarget)" 
                    @mouseout="pauseVideo($event.currentTarget)"
                  >
                    <video muted loop class="background-video" poster="/images/poster-dogs.png">
                      <source src="/images/videos/dog.mp4" type="video/mp4" >
                      Your browser does not support the video tag.
                    </video>
                    <h4 class="switch-white title-top-left"></h4>
                    <div class="container cover-panel">
                      <div class="row">
                        <div class="col-md-9">
                          <p class="switch-white"><strong>Dog parks</strong> - For your furry family members, they can enjoy some off-leash activity in a safe park...</p>
                        </div>
                        <div class="col-md-3">
                          <button class="fullscreen-btn btn-small mt-3" @click="viewFullScreen($event.currentTarget)"><small>Watch Video</small></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 mb-4">
                  <div 
                    class="switch-bg-lblue p-5 h-100 video-container" 
                    @mouseover="playVideo($event.currentTarget)" 
                    @mouseout="pauseVideo($event.currentTarget)"
                  >
                    <video muted loop class="background-video" poster="/images/poster-running.png">
                      <source src="/images/videos/jogging.mp4" type="video/mp4" >
                      Your browser does not support the video tag.
                    </video>
                    <h4 class="switch-white title-top-left"></h4>
                    <div class="container cover-panel">
                      <div class="row">
                        <div class="col-md-9">
                          <p class="switch-white"><strong>Walking/jogging trails</strong> - From the young to the elderly, take advantage of safe trails for your daily exercise...</p>
                        </div>
                        <div class="col-md-3">
                          <button class="fullscreen-btn btn-small mt-3" @click="viewFullScreen($event.currentTarget)"><small>Watch Video</small></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 mb-4">
                  <div 
                    class="switch-bg-lblue p-5 h-100 video-container" 
                    @mouseover="playVideo($event.currentTarget)" 
                    @mouseout="pauseVideo($event.currentTarget)"
                  >
                    <video muted loop class="background-video" poster="/images/poster-courts.png">
                      <source src="/images/videos/court.mp4" type="video/mp4" >
                      Your browser does not support the video tag.
                    </video>
                    <h4 class="switch-white title-top-left"></h4>
                    <div class="container cover-panel">
                      <div class="row">
                        <div class="col-md-9">
                          <p class="switch-white"><strong>Court sports</strong> - Most estates have multi-functional courts for playing soccer, netball, tennis, and basketball...</p>
                        </div>
                        <div class="col-md-3">
                          <button class="fullscreen-btn btn-small mt-3" @click="viewFullScreen($event.currentTarget)"><small>Watch Video</small></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 mb-4">
                  <div 
                    class="switch-bg-lblue p-5 h-100 video-container" 
                    @mouseover="playVideo($event.currentTarget)" 
                    @mouseout="pauseVideo($event.currentTarget)"
                  >
                    <video muted loop class="background-video" poster="/images/poster-bikes.png">
                      <source src="/images/videos/bike.mp4" type="video/mp4" >
                      Your browser does not support the video tag.
                    </video>
                    <h4 class="switch-white title-top-left"></h4>
                    <div class="container cover-panel">
                      <div class="row">
                        <div class="col-md-9">
                          <p class="switch-white"><strong>Mountain biking</strong> - Farmlands, vineyards, and mountains form the perfect backdrop for your more adventurous side...</p>
                        </div>
                        <div class="col-md-3">
                          <button class="fullscreen-btn btn-small mt-3" @click="viewFullScreen($event.currentTarget)"><small>Watch Video</small></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>



              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>
<script>

import Menu from '@/components/Menu.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Menu, Footer 
  },
  mounted() {
  },
  data() {
    return {
      data: {
        listings: []
      }
    }
  },
  methods: {
    playVideo(element) {
      const video = element.querySelector('.background-video');
      video.play();
    },
    pauseVideo(element) {
      const video = element.querySelector('.background-video');
      video.pause();
    },
    viewFullScreen(button) {
      const video = button.closest('.video-container').querySelector('.background-video');
      if (video.requestFullscreen) {
        video.requestFullscreen();
      } else if (video.webkitRequestFullscreen) { // Safari
        video.webkitRequestFullscreen();
      } else if (video.msRequestFullscreen) { // IE11
        video.msRequestFullscreen();
      }
    }
  }
}
</script>

<style>

.video-container {
  position: relative;
  width: 100%;
  min-height: 290px; /* Set a minimum height */
  overflow: hidden;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

/* Title at the top-left */
.title-top-left {
  position: absolute;
  top: 0;
  left: 0;
  margin: 10px;
  z-index: 2;
  color: white;
}

/* Bottom text section with full width */
.cover-panel {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(18, 78, 131, 0.5); /* Opaque blue background */
  padding: 10px;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fullscreen-btn {
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  padding: 5px 10px;
  cursor: pointer;
}

.fullscreen-btn:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.discover {
    background: url("/images/winelands-discover.jpg") no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
}
</style>